import React from "react";
import { Helmet } from "react-helmet";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

export default function TrackIt() {
  return (
    <>
      <Helmet>
        <title>Track It</title>
        <meta name="description" content="Track It" />
      </Helmet>
      <Header />
      <Box bgcolor="#FAFBFB" pb={15}>
        <Container>
          <Typography variant="h1" sx={{ pt: "82px" }}>
            Track It
          </Typography>
          <Typography color="#FF0000" fontSize={26}>
            Coming Soon
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <Typography variant="body1" sx={{ mt: 3, whiteSpace: "pre-line" }}>
                {`Track the movement of your assets in real time. Anytime, Anywhere.`}
              </Typography>
            </Grid>
            <Hidden>
              <Grid item md={7}>
                <Box p={2}>
                  <Box
                    position="relative"
                    component="img"
                    maxWidth="calc(100% - 6px)"
                    src="/images/solutions-track-it.png"
                    alt=""
                  />
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </>
  );
}
