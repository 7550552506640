import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

const styles = {
  button: {
    fontSize: { xs: 14, md: 16 },
    fontWeight: 400,
    justifyContent: "flex-start",
    color: "#144765",
  },
};

export default function Solutions({ sx }) {
  return (
    <PopupState variant="popper" popupId="demoPopper">
      {(popupState) => (
        <div>
          <Button color="secondary" sx={sx} {...bindToggle(popupState)}>
            Solutions
          </Button>
          <Popper {...bindPopper(popupState)} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper sx={{ bgcolor: "#fafafa" }}>
                  <Box p={1} display="flex" flexDirection="column">
                    <Button
                      sx={styles.button}
                      component={Link}
                      to="/asset-capture"
                      color="secondary"
                    >
                      Asset Capture
                    </Button>
                    <Button
                      sx={styles.button}
                      component={Link}
                      to="/inventory-management"
                      color="secondary"
                    >
                      Inventory Management
                    </Button>
                    <Button
                      sx={styles.button}
                      component={Link}
                      to="/warehouse-management"
                      color="secondary"
                    >
                      Warehouse Management
                    </Button>
                    <Button
                      sx={styles.button}
                      component={Link}
                      to="/track-it"
                      color="secondary"
                    >
                      Track It
                    </Button>
                  </Box>
                </Paper>
              </Fade>
            )}
          </Popper>
        </div>
      )}
    </PopupState>
  );
}
