import { Helmet } from "react-helmet";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function InventoryManagement() {
  return (
    <>
      <Helmet>
        <title>Inventory Management</title>
        <meta name="description" content="Inventory Management" />
      </Helmet>
      <Header />
      <Box bgcolor="#FAFBFB" pb={15}>
        <Container>
          <Typography variant="h1" sx={{ pt: "82px" }}>
            Inventory Management
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Typography variant="body1" sx={{ mt: 3, whiteSpace: "pre-line" }}>
                {`Manage your assets with your own virtual store and shopping experience.  Customized work orders, reservations, restocking, scheduling and more. `}
                <Typography mt={5}>
                  <CheckCircleIcon
                    sx={{ fontSize: 16, color: "#00698C", mb: "-2px" }}
                  />{" "}
                  Streamline orders and fulfillment with easy-to-build kits
                </Typography>
                <Typography mt={0.5}>
                  <CheckCircleIcon
                    sx={{ fontSize: 16, color: "#00698C", mb: "-2px" }}
                  />{" "}
                  Efficiently browse and request inventory for deliveries
                </Typography>
                <Typography mt={0.5}>
                  <CheckCircleIcon
                    sx={{ fontSize: 16, color: "#00698C", mb: "-2px" }}
                  />{" "}
                  Secure inventory for future demands with our reservation feature
                </Typography>
                <Typography mt={0.5}>
                  <CheckCircleIcon
                    sx={{ fontSize: 16, color: "#00698C", mb: "-2px" }}
                  />{" "}
                  Reduce administrative time and increase forecasting with self-managed stock levels
                </Typography>
              </Typography>
            </Grid>
            <Hidden>
              <Grid item md={7}>
                <Box p={2}>
                  <Box
                    position="relative"
                    component="img"
                    maxWidth="calc(100% - 6px)"
                    src="/images/solutions-inventory-management.png"
                    alt=""
                  />
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </>
  );
}