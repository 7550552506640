import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import ManagementItem from "./ManagementItem";

export default function AssetManagement() {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  return (
    <Box bgcolor="#E3F4F8">
      <Container style={{ paddingRight: 0 }}>
        <Box
          maxWidth="100%"
          overflow="hidden"
          sx={{
            backgroundImage: `${
              isDesktop ? "url(/images/management.png)," : ""
            } url(/images/cloud-small.png), url(/images/cloud-medium.png), url(/images/cloud-big.png)`,
            backgroundPosition: `${
              isDesktop ? "right 550px," : ""
            } 313px 57px, 442px 955px, left 247px`,
            backgroundRepeat: "no-repeat",
          }}
        >
          <Typography
            pt="173px"
            variant="h1"
            fontSize="38px"
            sx={{ display: { xs: "none", md: "block" }, textAlign: "center" }}
          >
            A Real-Time Asset & Warehouse Management System
          </Typography>
          <ManagementItem
            title="Why Glimpse?"
            items={[]}
            fullWidth={true}
          >
            Managing assets from the capturing data through cross docking and
            storage are crucial for business and logistical operations.
            Manual methods of asset and warehouse management are labor intensive, error-prone and time consuming.
            Glimpse is real time solution that improves accuracy, enhanced efficiency, real-time
            visibility, optimizes space utilization, increases productivity while producing
            analytics and reporting that support scalability.
          </ManagementItem>
          <ManagementItem
            title="ASSET CAPTURE"
            items={[
              "Access your assets anytime, anywhere, on any device",
              "Rapid data editing capabilities",
              "Customizable reporting",
              "Seamlessly capture your data online or offline"
            ]}
            fullWidth={false}
          >
            Effortlessly capture, organize and manage all of your assets from a single
            cloud based app or web application. Our solution is designed to adapt to
            your unique operational needs, allowing customization to capture only the
            data fields you need.
          </ManagementItem>
          <ManagementItem
            title="INVENTORY MANAGEMENT"
            items={[
              "Streamline orders and fulfillment with easy-to-build kits",
              "Efficiently browse and request inventory for deliveries",
              "Secure inventory for future demands with our reservation feature",
              "Reduce administrative time and increase forecasting with self managed stock levels"
            ]}
            fullWidth={false}
          >
            Manage your assets with your own virtual store and shopping experience.
            Customized work orders, reservations, restocking, scheduling and more.
          </ManagementItem>
          <ManagementItem
            title="WAREHOUSE MANAGEMENT"
            items={[
              "Cross-docking and transfer monitoring and management",
              "Comprehensive 2-D view of your warehouse at a glance",
              "Live occupancy metrics for optimal storage efficiency",
              "Tracking of bill of ladings and packing slips",
              "Analytics and reporting features"
            ]}
            fullWidth={false}
          >
            Automate your warehouse with enhanced efficiency through the Glimpse tools.
            Optimize and automate tasks, tracking, storage vacancy, labor, and billing on one platform.
            With Glimpse, your operations will be able to streamline processes and maximize space and
            productivity, while reducing costs and errors and enhancing accuracy and customer service.
          </ManagementItem>
          <Box pt={{ xs: "28px", md: "120px" }} />
          <Box
            display={{ xs: "flex", lg: "none" }}
            justifyContent="space-between"
          >
            <Box>
              <img src="/images/website.png" alt="" />
            </Box>
            <Box mt="106px" mb="-27px">
              <img src="/images/binoculars.png" alt="" />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
