import { Helmet } from "react-helmet";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";

import Header from "./Header/Header";
import Footer from "./Footer/Footer";

export default function AboutUs() {
  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta name="description" content="About Us" />
      </Helmet>
      <Header />
      <Box bgcolor="#FAFBFB">
        <Container>
          <Typography variant="h1" sx={{ pt: "82px" }}>
            About Us
          </Typography>
          <Box display="flex">
            <Typography variant="body1" sx={{ mt: 3, whiteSpace: "pre-line" }}>
              {`Glimpse was founded by CEO, Amy Garber in 2019 out of the need to solve a problem. As the President and Founder of Visions Management, a project management firm based in the San Francisco Bay Area, Amy and her team were tasked with capturing, tracking and managing the moving, storage and donation of over 16,000 assets for a single client. At the same time, her team was retained by multiple clients globally to pack up, ship and track employee content and personal items due to the global pandemic and shutdown of offices.

Recognizing a gap in the marketplace, Amy had a desire to create a system that was easy to use, customizable and allowed for unlimited users that demonstrated her passion for detail, organization, process and compassion. What evolved from this process was Glimpse, an asset tracking system that captures, tracks and reports on your most important assets. Since the inception, Glimpse has been used to track thousands of assets.

As a former Division 1 athlete and serial entrepreneur, Amy is passionate about solving problems, providing solutions and building teams that pride themselves on integrity, innovation and good old fashion hard work.`}
            </Typography>
            <Hidden smDown>
              <img src="/images/walk-people.png" alt="" />
            </Hidden>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
}
