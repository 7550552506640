import React from "react";
import { Helmet } from "react-helmet";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function AssetCapture() {
  return (
    <>
      <Helmet>
        <title>Asset Capture</title>
        <meta name="description" content="Asset Capture" />
      </Helmet>
      <Header />
      <Box bgcolor="#FAFBFB" pb={15}>
        <Container>
          <Typography variant="h1" sx={{ pt: "82px" }}>
            Asset Capture
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <Typography variant="body1" sx={{ mt: 3, whiteSpace: "pre-line" }}>
                {`Effortlessly capture, organize  and manage all of your assets from a single cloud based app or web application. Our solution is designed to adapt to your unique operational needs, allowing customization to capture only the data fields you need.`}
                <Typography mt={5}>
                  <CheckCircleIcon
                    sx={{ fontSize: 16, color: "#00698C", mb: "-2px" }}
                  />{" "}
                  Access your assets anytime, anywhere, on any device
                </Typography>
                <Typography mt={0.5}>
                  <CheckCircleIcon
                    sx={{ fontSize: 16, color: "#00698C", mb: "-2px" }}
                  />{" "}
                  Rapid data editing capabilities
                </Typography>
                <Typography mt={0.5}>
                  <CheckCircleIcon
                    sx={{ fontSize: 16, color: "#00698C", mb: "-2px" }}
                  />{" "}
                  Customizable reporting
                </Typography>
                <Typography mt={0.5}>
                  <CheckCircleIcon
                    sx={{ fontSize: 16, color: "#00698C", mb: "-2px" }}
                  />{" "}
                  Seamlessly capture your data online or offline
                </Typography>
              </Typography>
            </Grid>
            <Hidden>
              <Grid item md={7}>
                <Box p={2}>
                  <Box
                    position="relative"
                    component="img"
                    maxWidth="calc(100% - 6px)"
                    src="/images/solutions-asset-capture.png"
                    alt=""
                  />
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </>
  );
}
